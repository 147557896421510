<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-6" style="padding: 40px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/Untitled-2.svg" alt="">
                    </div>
                   
                </div>
                <div class="col-md-6">
                    <div class="text-center" style="margin-top: 100px;">
                        <h1 class="text-left" style="font-weight: 800;color: #242582;">Contact US</h1>
                        <div class="block-23 mb-3 text-center">
                            <ul>
                              <li><span class="icon icon-map-marker"></span><span class="text text-1 text-left">Door No:23/163,23/164 <br> Bicha Complex, Near Sindhu Theatre, Wayanad Road, Kunnamangalam Calicut,Kerala</span></li>
                              <li><a href="#"><span class="icon icon-phone"></span><span class="text text-1">+91 9847002642 (GCC JOBS)</span></a></li>
                              <li><a href="#"><span class="icon icon-phone"></span><span class="text text-1">+91 8138922642 (NURSING JOBS)</span></a></li>
                              <li><a href="#"><span class="icon icon-phone"></span><span class="text text-1">+91 8078880074</span></a></li>
                              <li><a href="#"><span class="icon icon-envelope"></span><span class="text text-1">bichatravels89@gmail.com</span></a></li>
                            </ul>
                          </div>
                        <!-- <button class="btn btn-primary">SEND THE MAIL</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</section>
