import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gcc',
  templateUrl: './gcc.component.html',
  styleUrls: ['./gcc.component.css']
})
export class GccComponent implements OnInit {

  flags = [
    { id: 1, name: 'Client 1', flagUrl: 'assets/images/countries-flag/SA.png'},
    { id: 1, name: 'Client 1', flagUrl: 'assets/images/countries-flag/uae.png'},
    { id: 1, name: 'Client 1', flagUrl: 'assets/images/countries-flag/oman.png'},
    { id: 1, name: 'Client 1', flagUrl: 'assets/images/countries-flag/qatar.png'},
    { id: 1, name: 'Client 1', flagUrl: 'assets/images/countries-flag/kuwait.png'},
    { id: 1, name: 'Client 1', flagUrl: 'assets/images/countries-flag/bahrain.png'}
    // Add more client objects as needed
  ];

  clients = [
    { id: 1, name: 'Client 1', logoUrl: 'assets/images/gcc/ajex.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/al-baik.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/americana.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/baumat.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/coacola.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/dawood.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/general_trading.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/gmc.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/inno.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/kaprel.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/kudu.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/life.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/modern_bus.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/nabati.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/nadec.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/paris.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/pizza.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/qaid.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/saif.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/savola.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/shaker.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/gcc/sunbula.jpeg' },


    // Add more client objects as needed
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
