<div class="hero-wrapjs-fullheight" style="background-image: url('./../../assets/images/bg_2.jpg');" data-stellar-background-ratio="0.5">
  <div class="container">
     <div class="row no-gutters slider-text  align-items-center justify-content-start"
        data-scrollax-parent="true">
        <div class="col-xl-10 ftco-animate mb-5 pb-5" >
           <p class="mb-4 mt-5 pt-5" >We have <span
              class="number" data-number="850000"></span> great job offers you deserve!</p>
           <h1 class="mb-5" >Your Dream
              <br><h2>Job is Waiting</h2>
           </h1>
           <div class="ftco-search">
              <div class="row">
                 <div class="col-md-12 nav-link-wrap">
                    <div class="nav nav-pills text-center" id="v-pills-tab" role="tablist"
                       aria-orientation="vertical">
                       <a class="nav-link ts-cursor mr-md-1" routerLink="jobs" routerLinkActive="active" 
                          role="tab">Find a Job</a> 
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
</div>
<section class="ftco-section services-section bg-light">
  <div class="container">
     <div class="row d-flex">
        <div routerLink="healthcare" class="col-md-3 d-flex align-self-stretch ftco-animate box-navi">
           <div class="media services d-block">
              <div class="icon"><span class="flaticon-collaboration"></span></div>
              <div class="media-body">
                 <h3  class="heading mb-3">Health Care</h3>
                 <span>All the country’s healthcare sectors have a pivotal role to play in society..</span>
              </div>
           </div>
        </div>
        <div routerLink="europe" class="col-md-3 d-flex align-self-stretch ftco-animate box-navi">
           <div class="media services d-block">
              <div class="icon"><span class="flaticon-collaboration"></span></div>
              <div class="media-body">
                 <h3 class="heading mb-3">Europe</h3>
                 <span>Working and living in Europe is enticing and exciting..</span>
              </div>
           </div>
        </div>
        <div routerLink="gcc" class="col-md-3 d-flex align-self-stretch ftco-animate box-navi">
           <div class="media services d-block">
              <div class="icon"><span class="flaticon-promotions"></span></div>
              <div class="media-body">
                 <h3 class="heading mb-3">GCC</h3>
                 <span>We are among the top foreign recruiters in India for Gulf countries..</span>
              </div>
           </div>
        </div>
        <div routerLink="jobs" class="col-md-3 d-flex align-self-stretch ftco-animate box-navi">
           <div class="media services d-block">
              <div class="icon"><span class="flaticon-employee"></span></div>
              <div class="media-body">
                 <h3 class="heading mb-3">Jobs</h3>
                 <span>A small river named Duden flows by their place and supplies..</span>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<section class="ftco-section ftco-counter">
  <div class="container">
      <div class="row justify-content-center mb-5 pb-3">
    <div class="col-md-7 heading-section text-center ftco-animate">
        <span class="subheading">Categories work wating for you</span>
      <h2 class="mb-4"><span class="subheading-part">Current</span> Job Posts</h2>
    </div>
  </div>
  <div class="row">
      <div class="col-md-3 ftco-animate">
          <ul class="category">
              <li><a href="#">Web Development <span class="number" data-number="1000">0</span></a></li>
              <li><a href="#">Graphic Designer <span class="number" data-number="1000">0</span></a></li>
              <li><a href="#">Multimedia <span class="number" data-number="2000">0</span></a></li>
              <li><a href="#">Advertising <span class="number" data-number="900">0</span></a></li>
          </ul>
      </div>
      <div class="col-md-3 ftco-animate">
          <ul class="category">
              <li><a href="#">Education &amp; Training <span class="number" data-number="3500">0</span></a></li>
              <li><a href="#">English <span class="number" data-number="1560">0</span></a></li>
              <li><a href="#">Social Media <span class="number" data-number="1000">0</span></a></li>
              <li><a href="#">Writing <span class="number" data-number="2500">0</span></a></li>
          </ul>
      </div>
      <div class="col-md-3 ftco-animate">
          <ul class="category">
              <li><a href="#">PHP Programming <span class="number" data-number="5500">0</span></a></li>
              <li><a href="#">Project Management <span class="number" data-number="2000">0</span></a></li>
              <li><a href="#">Finance Management <span class="number" data-number="800">0</span></a></li>
              <li><a href="#">Office &amp; Admin <span class="number" data-number="7000">0</span></a></li>
          </ul>
      </div>
      <div class="col-md-3 ftco-animate">
          <ul class="category">
              <li><a href="#">Web Designer <span><span class="number" data-number="8000">0</span></span></a></li>
              <li><a href="#">Customer Service <span class="number" data-number="4000">0</span></a></li>
              <li><a href="#">Marketing &amp; Sales <span class="number" data-number="3300">0</span></a></li>
              <li><a href="#">Software Development <span class="number" data-number="1356">0</span></a></li>
          </ul>
      </div>
  </div>
  </div>
  </section>
<section class="ftco-section ftco-counter bg-light-grey">
  <div class="container">
     <div class="row justify-content-center mb-5 pb-3">
        <div class="col-md-7 heading-section text-center ftco-animate">
           <span class="subheading">Explore Our Latest Opportunities</span>
           <h2 class="mb-4"><span class="subheading-part">Recent</span> Jobs</h2>
        </div>
     </div>
     <div class="row">
        <div class="col-md-12 ftco-animate"  *ngFor="let data of latestJobs">
           <div class="job-post-item bg-white p-4 d-block d-md-flex align-items-center">
              <div class="mb-4 mb-md-0 mr-5">
                 <div class="job-post-item-header d-flex align-items-center">
                    <h2 class="mr-3 text-black h4">{{data.title}}</h2>
                    <div class="badge-wrap">
                       <span class="bg-warning text-white badge py-2 px-3">{{data.salary}}</span>
                    </div>
                 </div>
                 <div class="job-post-item-body d-block d-md-flex">
                    <div class="mr-3"><span class="icon-layers"></span> <a href="#">Posted On : {{data.posted_on}}</a></div>
                    <div><span class="icon-my_location"></span> <span>{{data.location}}</span></div>
                 </div>
              </div>
              <div class="ml-auto d-flex">
                 <a (click)="openModal(data)"  class="btn btn-primary py-2 mr-1" >More..</a>
                 <!-- <a href="#" class="btn btn-danger rounded-circle btn-favorite d-flex align-items-center">
                    <span class="icon-heart"></span>
                    </a> -->
              </div>
           </div>
        </div>
        <!-- end -->
     </div>
     <div class="row mt-5">
        <div class="col text-right ts-cursor">
           <span style="color: blue;" routerLink="jobs">More Jobs...</span>
        </div>
     </div>
  </div>
</section>
<section class="ftco-section ftco-counter">
   <div class="container">
       <div class="row justify-content-center mb-5 pb-3">
     <div class="col-md-7 heading-section text-center ftco-animate">
         <span class="subheading">Discover Our Diverse Client Network</span>
       <h2 class="mb-4"><span class="subheading-part">Our</span> Clients</h2>
     </div>
   </div>
   <div class="row">
      <div class="logo-container">
         <div class="logos">
            <div *ngFor="let client of clients">
              <img [src]="client.src" [alt]="client.alt" width="150">
            </div>
          </div>
      </div>
   </div>
   </div>
</section>
