import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { IndexComponent } from './components/index/index.component';
import { AboutComponent } from './components/about/about.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { ModalEnquiryComponent } from './components/modal-enquiry/modal-enquiry.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalJobDetailsComponent } from './components/modal-job-details/modal-job-details.component';
import { ContactComponent } from './components/contact/contact.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http'
import "@angular/compiler"
import { from } from 'rxjs';
import { HealthcareComponent } from './components/healthcare/healthcare.component';
import { EuropeComponent } from './components/europe/europe.component';
import { GccComponent } from './components/gcc/gcc.component';
import { ClientsComponent } from './components/clients/clients.component';
import { EventsComponent } from './components/events/events.component';
import { NursesComponent } from './components/healthcare/nurses/nurses.component';
import { PharamacistsComponent } from './components/healthcare/pharamacists/pharamacists.component';
import { MedicalTechniciansComponent } from './components/healthcare/medical-technicians/medical-technicians.component';
import { DoctorsComponent } from './components/healthcare/doctors/doctors.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent,
    FooterComponent,
    IndexComponent,
    AboutComponent,
    JobsComponent,
    ModalEnquiryComponent,
    ModalJobDetailsComponent,
    ContactComponent,
    HealthcareComponent,
    EuropeComponent,
    GccComponent,
    ClientsComponent,
    EventsComponent,
    NursesComponent,
    PharamacistsComponent,
    MedicalTechniciansComponent,
    DoctorsComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModalModule,
    HttpClientModule,
    //  ,
    FormsModule,
    NgSelectModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 5000,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    }),

  ],
  providers: [
  ],
  
  bootstrap: [AppComponent],
  // entryComponents:[]
  entryComponents: [ModalJobDetailsComponent]
})
export class AppModule { }
