import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nurses',
  templateUrl: './nurses.component.html',
  styleUrls: ['./nurses.component.css']
})
export class NursesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
