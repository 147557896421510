<div class="myNewLoader_main" *ngIf="loading">
  <div class="myNewLoader_loader">
     <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
     </div>
  </div>
</div>
<section class="ftco-section ftco-counter bg-light">
  <div class="container">
     <div class="row justify-content-center mb-5 pb-3">
        <div class="col-md-7 heading-section text-center ftco-animate">
           <span class="subheading">Discover Your Next Opportunity</span>
           <h2 class="mb-4"><span class="subheading-part">Find Your</span> Jobs</h2>
        </div>
     </div>
     <div class="row">
        <!-- filters -->
        <div class="col-md-4">
           <div class="col-md-12 ftco-animate">
               <ul class="category">
                   <li><a href="#">Web Development <span class="number" data-number="1000">0</span></a></li>
                   <li><a href="#">Graphic Designer <span class="number" data-number="1000">0</span></a></li>
                   <li><a href="#">Multimedia <span class="number" data-number="2000">0</span></a></li>
                   <li><a href="#">Advertising <span class="number" data-number="900">0</span></a></li>
                   <li><a href="#">Web Development <span class="number" data-number="1000">0</span></a></li>
                   <li><a href="#">Graphic Designer <span class="number" data-number="1000">0</span></a></li>
                   <li><a href="#">Multimedia <span class="number" data-number="2000">0</span></a></li>
                   <li><a href="#">Advertising <span class="number" data-number="900">0</span></a></li>
               </ul>
           </div>
        </div>
        <!-- jobs -->
        <div class="col-md-8">
        <div class="row">
           <div class="col-md-12 ftco-animate" *ngFor="let data of jobData">
              <div class="job-post-item bg-white p-4 d-block d-md-flex align-items-center">
                 <div class="mb-4 mb-md-0 mr-5">
                    <div class="job-post-item-header d-flex align-items-center">
                       <h2 class="mr-3 text-black h3">{{data.title}}</h2>
                       <div class="badge-wrap">
                          <span class="bg-primary text-white badge py-2 px-3">{{data.salary}}</span>
                       </div>
                    </div>
                    <div class="job-post-item-body d-block d-md-flex">
                       <div class="mr-3"><span class="icon-layers"></span> <a>Posted On : {{data.posted_on}}</a></div>
                       <div><span class="icon-my_location"></span> <span>{{data.location}}</span></div>
                    </div>
                 </div>
                 <div class="ml-auto d-flex">
                    <a  (click)="openModal(data)" class="btn btn-primary py-2 mr-1">More..</a>
                    <!-- <a class="btn btn-secondary rounded-circle btn-favorite d-flex align-items-center icon">
                       <span class="icon-heart"></span>
                       </a> -->
                 </div>
              </div>
           </div>
           <!-- end -->
        </div>
     </div>
     </div>
     <!-- <div class="row mt-5">
        <div class="col text-center">
          <div class="block-27">
            <ul>
              <li><a href="#">&lt;</a></li>
              <li class="active"><span>1</span></li>
              <li><a href="#">2</a></li>
              <li><a href="#">3</a></li>
              <li><a href="#">4</a></li>
              <li><a href="#">5</a></li>
              <li><a href="#">&gt;</a></li>
            </ul>
          </div>
        </div>
        </div> -->
  </div>
</section>