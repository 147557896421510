<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <h1 style="font-weight: 800;color: #242582;">Medical Technicians</h1>
                        <p>
                            Medical technicians are held responsible to apply technologies, therapies, tests, and techniques to address health conditions. The different types of healthcare sectors, including pharmaceuticals, clinical supply, and veterinary care, require medical technicians of all stripes. We are looking to hire medical professionals in the fields of X-ray, lab, ECG, dialysis, radiology, etc. for all the GCC countries.
                             </p>
                        <!-- <button class="btn btn-primary">SEND THE MAIL</button> -->
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 100px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/7659acb627bb807333426f8a11fa775d.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</section>
