import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/service/jobs.service';
import { ModalJobDetailsComponent } from '../modal-job-details/modal-job-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  public loading;
  jobData: any;

  constructor(private service : JobsService,
              private _NgbModal: NgbModal,) { }

  ngOnInit() {
    this.loading = false;
    this.jobData = [
      { "title":"Driver", "salary":"42000", "posted_on":"20-10-2023","location":"Mumbai" },
      { "title":"Sales Man", "salary":"42000", "posted_on":"20-10-2023","location":"Mumbai" },
      { "title":"Cook", "salary":"42000", "posted_on":"20-10-2023","location":"Mumbai" },

    ]
    // this.getAllJobs();
  }
  getAllJobs(){
    this.loading = true;
    this.service.getJobsList().subscribe(data=>{
      this.jobData = data
      this.loading = false;
      console.log("data",data)
    })
  }
  openModal(datas){
    const modalRef = this._NgbModal.open(ModalJobDetailsComponent, {
      windowClass: 'modal-job-scrollable'
    });
    modalRef.componentInstance.data = datas
  }

}
