<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <h1 style="font-weight: 800;color: #242582;">GCC Jobs</h1>
                            <p>
                                We are among the top foreign recruiters in India for Gulf countries thanks to our enormous network of international placements in all successful job locations. To analyze  the data regarding open positions at commercial organizations in Gulf nations, we have hired a dynamic group of Gulf recruitment experts.
                            </p>
                            <p>
                                Employers as well as job applicants have benefited from our Gulf recruitment services. People looking for employment possibilities in Gulf nations consider us to be an excellent source for connecting them with positions that fit their qualifications and experience. On the other side, Gulf businesses put the highest faith in us to provide personnel matching their essential competencies. The database of the resources that are available and prevailing opportunities in the Gulf countries are updated frequently by our team.
                            </p>
                            <p>
                                Therefore, Bicha Travels & HR Consultant is the right place if you're looking for talent for your corporation in Gulf nations or want to deal with a trustworthy company. We work arduously to maintain our position as the top Gulf recruitment service provider and give a clear service at reasonable costs. Our main goal is to eliminate world unemployment with our comprehensive foreign recruitment services.
                            </p>
                        <!-- <button class="btn btn-primary">SEND THE MAIL</button> -->
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 220px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/7659acb627bb807333426f8a11fa775d.jpg" height="626" width="626" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ftco-section ftco-counter bg-light-grey">
    <div class="container">
      <div class="row justify-content-center mb-5 pb-3">
        <div class="col-md-7 heading-section text-center ftco-animate">
            <span class="subheading">We are working in 15+ countries with the best companies around the world.</span>
          <h2 class="mb-4"><span class="subheading-part">Top places we serve</span> </h2>
        </div>
      </div>
      <div class="flag-list">
        <div class="flag" *ngFor="let flag of flags">
            <img [src]="flag.flagUrl" [alt]="flag.name">
        </div>
      </div>
    </div>
</section>

<section class="ftco-section ftco-counter bg-light">
    <div class="container">
      <div class="row justify-content-center mb-5 pb-3">
        <div class="col-md-7 heading-section text-center ftco-animate">
            <span class="subheading">Our Trusted GCC Partners</span>
          <h2 class="mb-4"><span class="subheading-part">GCC</span> Clients</h2>
        </div>
      </div>
      <div class="row">
          <div class="col-md-3 ftco-animate" *ngFor="let client of clients">
            <div class="logo">
              <img [src]="client.logoUrl" [alt]="client.name" width="150">
            </div>
          </div>
      </div>
    </div>
</section>
