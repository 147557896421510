<section>
    <div class="container">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="form" >
                        <h1 class="text-center">Candidate Information Form</h1>
                        <div class="info">
                            <div class="row">
                                <div class="col-md-6">
                                    <input class="fname" type="text" [(ngModel)]="fName" name="fname" placeholder="First name">
                                    <p *ngIf="fNameReq == true" style="color: red;">First Name Required*</p>
                                </div>
                                <div class="col-md-6">
                                    <input class="fname" type="text" [(ngModel)]="lName" name="lname" placeholder="Last name">
                                </div>                   
                             </div>
                          <input type="email" name="email" [(ngModel)]="email" placeholder="Email">
                          <div class="row">
                              <div class="col-md-6">
                                <input type="date" name="name" [(ngModel)]="dob" placeholder="Date Of Birth">
                              </div>
                              <div class="col-md-6">
                                <!-- <input type="text" name="name" placeholder="Gender"> -->
                                <select name="cars" class="select" (change)="changeGender($event.target.value)" id="cars">
                                    <option >Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                  </select> 
                                  <p *ngIf=" genderReq == true" style="color: red;">Gender Required*</p> 
                            </div>
                          </div>
            
                          <div class="row">
                              <div class="col-md-6">
                                <input type="tel" maxlength="10" name="name" [(ngModel)]="pNumber" placeholder="Primary Contact">
                                <p *ngIf="pNumberReq == true" style="color: red;">Phone Number Required*</p> 

                              </div>
                              <div class="col-md-6">
                                <input type="tel" maxlength="10" name="name" [(ngModel)]="sNumber" placeholder="Secondary Contact">
                            </div>
                          </div>
                          <input type="text" name="name" [(ngModel)]="place" placeholder="Place">
                          <ng-select bindLabel="name" [items]="jobRoles" [(ngModel)]="job" [multiple]="true"
                            placeholder="select your Desigination" class="custom select">
                        </ng-select>
                        <p *ngIf="jobsReq == true" style="color: red;">Select any Desigination*</p>

                        <input type="text"  name="name" [(ngModel)]="otherJob" placeholder="Other Desigination">
                        <!-- <p *ngIf="otherJobReq == true && jobsReq == true" style="color: red;">Enter any other Desigination*</p> -->

                        </div>
                        <p>Remarks</p>
                        <div>
                          <textarea  rows="4" [(ngModel)]="remarks"></textarea>
                        </div>
                        <button class="button ts-cursor"type="submit" (click)="validate()" >Submit</button>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            
        </div>
    </div>
</section>