<section class="ftco-section bg-light-grey">
    <div class="container">
        <div class="col-lg-12 col-md-12">
            <div class="row">
                
                <div class="text-center" style="margin-top: 100px; display: flex; justify-content: space-between; align-items: center;">
                    <h1 style="font-weight: 800; color: #242582; text-align: center;">How do we work with clients abroad</h1>
                    <button id="changeLanguage" class="custom-button" style="margin-left: auto;" (click)="changeLanguageFunc()">{{ lang }}</button>
                </div>
            </div>
                    
           
            <div class="row">
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <div id="englishContent">
                            <p style="text-align: left;">
                                Bicha Travels and HR Consultant is a leading recruitment agency helping clients globally across UAE, KSA, Kuwait, Qatar, Bahrain, and Europe to hire the right talents. 
                            </p> 
                            <p style="text-align: left;">
                                Bicha Travels offers comprehensive workforce solutions for all the  international human resource requirement. With more than three decades in business, we have considerable experience and a knowledgeable team to assist you with all your overseas staffing needs. Over the years, we have modified and organized our manpower supply services to pioneer the dynamic needs of the global market.
                            </p>
                            <p style="text-align: left;">
                                Leading companies choose Bicha Travels& HR consultant as their preferred recruitment agency in India. Our agency is the most sought-after one, which has recruited Indian manpower for every industry around the world. If employers are looking for recruiting Indian manpower in Healthcare for your company in Gulf, Middle East, Canada, Libya, UAE, Dubai, Saudi Arabia, Oman, Qatar, Russia, UK, USA, Malaysia, kindly mail your requirement to mail@bichatravels.com                          
                            </p>
                        </div>
                        <div id="arabicContent" style="display: none;">
                            <!-- Your Arabic content goes here --> 
                            <p style="text-align: right;">
                                تقدم Bicha Travels حلولاً شاملة للقوى العاملة لجميع متطلبات الموارد البشرية الدولية. مع أكثر من ثلاثة عقود في مجال الأعمال، لدينا خبرة كبيرة وفريق واسع المعرفة لمساعدتك في جميع احتياجات التوظيف في خارج الهند. على مر السنين، قمنا بتعديل وتنظيم خدمات توريد القوى العاملة لدينا لريادة الاحتياجات الديناميكية للسوق العالمية.
                            </p>
                            <p style="text-align: right;">
                                تختار الشركات الرائدة Bicha Travells & HR مستشار وكالة التوظيف المفضلة لديها في الهند. وكالتنا هي الوكالة الأكثر طلبًا، حيث قامت بتوظيف القوى العاملة الهندية لكل صناعة في جميع أنحاء العالم. إذا كان أصحاب العمل يبحثون عن توظيف القوى العاملة الهندية في مجال الرعاية الصحية في الخليج، والشرق الأوسط، وكندا، وليبيا، والإمارات العربية المتحدة، ودبي، والمملكة العربية السعودية، وعمان، وقطر، وروسيا، والمملكة المتحدة، والولايات المتحدة الأمريكية، وماليزيا، يرجى إرسال متطلباتهم بالبريد إلى mail@bichatravels.com                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 100px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/7659acb627bb807333426f8a11fa775d.jpg" height="526" width="526" alt="">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6" style="margin-top: 100px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/7659acb627bb807333426f8a11fa775d.jpg" height="526" width="526" alt="">
                    </div>
                </div>
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <div id="englishContent-1">
                            <p style="text-align: left;">
                                Our major focus is the Healthcare sector. We have an extensive database and network both regionally and internationally of healthcare professionals at all levels. We recognize the difficulties our clients face in recruiting healthcare professionals with the required qualifications both from within the region and internationally. We address this by working closely with our clients to fully understand their needs. Once these have been understood we take a proactive approach using our network to search and select the qualified candidates.  By the assistance received from the thealth care experts we assure our clients and candidates a superior level of service. 
                            </p>
                        </div>
                        <div id="arabicContent-1" style="display: none;">
                            <!-- Your Arabic content goes here --> 
                            <p style="text-align: right;">
                               تركيزنا الرئيسي هو قطاع الرعاية الصحية. لدينا قاعدة بيانات وشبكة واسعة على الصعيدين الإقليمي والدولي من المتخصصين في الرعاية الصحية على جميع المستويات. نحن ندرك الصعوبات التي يواجهها عملاؤنا في توظيف المتخصصين في الرعاية الصحية بالمؤهلات المطلوبة من داخل المنطقة وعلى الصعيد الدولي. نعالج هذا من خلال العمل عن كثب مع عملائنا لفهم احتياجاتهم بشكل كامل. بمجرد فهم هذه الأمور، نتخذ نهجًا استباقيًا باستخدام شبكتنا للبحث عن المرشحين المؤهلين واختيارهم. من خلال المساعدة التي نتلقاها من فريق خبراء الرعاية الصحية، نضمن لعملائنا وللمرشحين مستوى فائقًا من الخدمة.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
