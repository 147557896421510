import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEnquiryComponent } from '../modal-enquiry/modal-enquiry.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private _NgbModal: NgbModal,
  ) { }

  ngOnInit() {
  }

  // openModal(){
  //   this._NgbModal.open(ModalEnquiryComponent, {
  //     windowClass: 'modal-job-scrollable'
  //   });
  // }
}
