import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-europe',
  templateUrl: './europe.component.html',
  styleUrls: ['./europe.component.css']
})
export class EuropeComponent implements OnInit {

  flags = [
    { id: 1, name: 'Client 1', flagUrl: 'http://localhost:4200/assets/images/countries-flag/croatia.png/'},
    { id: 1, name: 'Client 1', flagUrl: 'http://localhost:4200/assets/images/countries-flag/czech.png'},
    { id: 1, name: 'Client 1', flagUrl: 'assets/images/countries-flag/Flag-Poland.png'},
    { id: 1, name: 'Client 1', flagUrl: 'http://localhost:4200/assets/images/countries-flag/hungary.png'},
    { id: 1, name: 'Client 1', flagUrl: 'http://localhost:4200/assets/images/countries-flag/germany.png'},
    { id: 1, name: 'Client 1', flagUrl: 'assets/images/countries-flag/unitedkingdom.png'},
    
    // Add more client objects as needed
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
