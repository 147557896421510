import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-job-details',
  templateUrl: './modal-job-details.component.html',
  styleUrls: ['./modal-job-details.component.css']
})
export class ModalJobDetailsComponent implements OnInit {
  @Input() data;
  constructor(
    private _NgbModal: NgbModal,
    private _NgbActiveModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    console.log("modal data",this.data)
  }



  get activeModal() {
    return this._NgbActiveModal;
  }

}
