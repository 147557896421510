import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  lang : string;

  constructor() { }

  ngOnInit(): void {
    this.lang = "عربى"
  
  }

  changeLanguageFunc(){
    if (this.lang == "عربى"){
      var arabicContent = document.getElementById("arabicContent");
      var arabicContent1 = document.getElementById("arabicContent-1");
      arabicContent.style.display = "block";
      arabicContent1.style.display = "block";
      var englishContent = document.getElementById("englishContent");
      var englishContent1 = document.getElementById("englishContent-1");
      englishContent.style.display = "none";
      englishContent1.style.display = "none";
      this.lang = "english"
    }
    else {
      var arabicContent = document.getElementById("arabicContent");
      var arabicContent1 = document.getElementById("arabicContent-1");
      arabicContent.style.display = "none";
      arabicContent1.style.display = "none";
      var englishContent = document.getElementById("englishContent");
      var englishContent1 = document.getElementById("englishContent-1");
      englishContent.style.display = "block";
      englishContent1.style.display = "block";
      this.lang = "عربى"
    }

  }

}
