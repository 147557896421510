import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  eventVideos: any;
  eventPhotos: any;

  constructor(private _NgbModal: NgbModal,) { }

  ngOnInit() {
    this.eventVideos = [
      {"link": "https://www.youtube.com/embed/Q5jzA2Zd2m8?controls=0", "title": "#Free Recruitment", "sub_title":"#Free Recruitment, Qatar #150 vacancies #FIFA #bicha"},
      {"link": "https://www.youtube.com/embed/Q5jzA2Zd2m8?controls=0", "title": "#Free Recruitment", "sub_title":"#Free Recruitment, Qatar #150 vacancies #FIFA #bicha"},
      {"link": "https://www.youtube.com/embed/Q5jzA2Zd2m8?controls=0", "title": "#Free Recruitment", "sub_title":"#Free Recruitment, Qatar #150 vacancies #FIFA #bicha"},
      {"link": "https://www.youtube.com/embed/Q5jzA2Zd2m8?controls=0", "title": "#Free Recruitment", "sub_title":"#Free Recruitment, Qatar #150 vacancies #FIFA #bicha"},
    ]
    this.eventPhotos = [
      {"link": "https://www.youtube.com/embed/Q5jzA2Zd2m8?controls=0", "title": "#Free Recruitment", "sub_title":"#Free Recruitment, Qatar #150 vacancies #FIFA #bicha"},
    ]
  
  }

}
