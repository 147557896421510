<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-12" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <h3 style="font-weight: 800;color: #242582;">Our events & client interviews</h3>
                        <p>
                            We offer you a secure, comfortable deployment abroad and we are distinct from others by particular processing throughout your overseas job journey. <br>
                            Bicha Travels& Hr Consultant is a leading healthcare recruitment agency registered with the Ministry of external affairs. So, you feel safe and fearless to be with us to fulfill your dreams. We guarantee that the end-to-end handling of your application is carried out straightforwardly and with steady back from our HR Administrators.
                             </p>
                        <!-- <button class="btn btn-primary">SEND THE MAIL</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</section>


<section>
  <div class="container">
    <div class="row">
      <div class="col-md-6" *ngFor="let data of eventVideos">
        <div class="card">
          <iframe height="390" src={{data.link}}> </iframe>
          <div class="card-body">
            hhh {{data}}
            <h5 class="card-title">Heehe ss{{data.title}} </h5>
            <p class="card-text">ddd{{data.sub_title}}dde</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section>
  <div class="container">
    <div class="row">
      <div class="col-md-6" *ngFor="let data of eventVideos">
        <div class="card">
          <iframe height="390" src={{data.link}}> </iframe>
          <div class="card-body">
            hhh {{data}}
            <h5 class="card-title">Heehe ss{{data.title}} </h5>
            <p class="card-text">ddd{{data.sub_title}}dde</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>