<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <h1 style="font-weight: 800;color: #242582;">Europe Jobs</h1>
                        <p>
                            Working and living in Europe is enticing and exciting. It's one of the most desirable places to have a job because of the harmonious fusion of modern lifestyle and rich historical legacy. People may desire to work in European countries to fulfill their most cherished dream as they would try to get a temporary or permanent work permit there.
                        </p>
                        <p>
                            India in recent days, witnessed a mushroom growth in the Recruiting Agencies for Europe jobs as the European countries are in extreme need of myriad number of employees in order to overcome their human resource shortage. However, recruiting appropriate candidates for these countries is supposed to be a herculean task for the concerned agencies. Given to thousands of recruiting firms in the country, what makes Bicha Travels & HR Consultant special is recruiting the most committed and talented manpower to Europe and providing the candidates with the best assistance
                        </p>
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 100px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/7659acb627bb807333426f8a11fa775d.jpg" height="626" width="626" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="countries-flag">
    <div class="container">
        <h1></h1>
        <h2></h2>
        <div class="flag-list">
          <div class="flag" *ngFor="let flag of flags">
          </div>
        </div>
    </div>
</section>


<section class="ftco-section ftco-counter bg-light-grey">
    <div class="container">
      <div class="row justify-content-center mb-5 pb-3">
        <div class="col-md-7 heading-section text-center ftco-animate">
            <span class="subheading">We are working in 15+ countries with the best companies around the world.</span>
          <h2 class="mb-4"><span class="subheading-part">Top places we serve</span> </h2>
        </div>
      </div>
      <div class="flag-list">
        <div class="flag" *ngFor="let flag of flags">
            <img [src]="flag.flagUrl" [alt]="flag.name">
        </div>
      </div>
    </div>
  </section>