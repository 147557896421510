<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <h1 style="font-weight: 800;color: #242582;">Health Care</h1>
                        <p class="main-des">All the country’s healthcare sectors have a pivotal role to play in society. Bicha Travels & HR Consultant is one of the leading health care recruitment agencies in Kerala, India. We devote all of our attention to the healthcare industry. We have an extensive record and connections with medical experts across all levels both locally and globally. We are aware of the challenges faced by our clients when trying to find qualified healthcare experts in the specific sectors. To combat this, we extensively collaborate with our clients to thoroughly comprehend their needs. With consultants who have experience working in the medical field, we can provide our clients  and candidates with a higher caliber of service once these have been understood. We adopt a proactive approach using our network to look for and choose the highest quality individuals.<br>
                             </p>
                        <!-- <button class="btn btn-primary">SEND THE MAIL</button> -->
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 100px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/7659acb627bb807333426f8a11fa775d.jpg" height="626" width="626" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ftco-section services-section bg-light">
  <div class="container">
    <div class="row d-flex">
      <div routerLink="nurses" class="col-md-3 d-flex align-self-stretch ftco-animate box-navi">
        <div class="media services d-block">
          <div class="icon"><span class="flaticon-collaboration"></span></div>
          <div class="media-body">
            <h3  class="heading mb-3">Nurses</h3>
            <span>Nurses are supposed to work as forerunners of health care all over..</span>
          </div>
        </div>      
      </div>
      <div routerLink="pharamacists" class="col-md-3 d-flex align-self-stretch ftco-animate box-navi">
        <div class="media services d-block">
          <div class="icon"><span class="flaticon-collaboration"></span></div>
          <div class="media-body">
            <h3 class="heading mb-3">Pharamacists</h3>
            <span>A pharmacist is an individual in the medical profession who is in charge..</span>
          </div>
        </div>    
      </div>
      <div routerLink="medical-technicians" class="col-md-3 d-flex align-self-stretch ftco-animate box-navi">
        <div class="media services d-block">
          <div class="icon"><span class="flaticon-promotions"></span></div>
          <div class="media-body">
            <h3 class="heading mb-3">Medical Technicians</h3>
            <span>Medical technicians are held responsible to apply technologies..</span>
          </div>
        </div>      
      </div>
      <div routerLink="doctors" class="col-md-3 d-flex align-self-stretch ftco-animate box-navi">
        <div class="media services d-block">
          <div class="icon"><span class="flaticon-employee"></span></div>
          <div class="media-body">
            <h3 class="heading mb-3">Doctors</h3>
            <span>We are one of the top worldwide doctor recruitment companies because..</span>
          </div>
        </div>      
      </div>
    </div>
  </div>
</section>

<section class="ftco-section ftco-counter bg-light-grey">
  <div class="container">
    <div class="row justify-content-center mb-5 pb-3">
      <div class="col-md-7 heading-section text-center ftco-animate">
          <span class="subheading">Our Trusted Healthcare Partners</span>
        <h2 class="mb-4"><span class="subheading-part">Healthcare</span> Clients</h2>
      </div>
    </div>
    <div class="row">
        <div class="col-md-3 ftco-animate" *ngFor="let client of clients">
          <div class="logo">
            <img [src]="client.logoUrl" [alt]="client.name" width="150">
          </div>
        </div>
    </div>
  </div>
</section>