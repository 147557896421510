<nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
    <div class="container">
      <a class="navbar-brand" href="index.html"><img style="width: 30%;" src="../../../assets/images/bicha-logo.png" alt="Bicha Travels Logo"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="oi oi-menu"></span> Menu
      </button>

      <div class="collapse navbar-collapse" id="ftco-nav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item"><a routerLink="/" routerLinkActive="active" class="nav-link">Home</a></li>
          <li class="nav-item"><a routerLink="jobs" routerLinkActive="active" class="nav-link">Jobs</a></li>
          <li class="nav-item"><a routerLink="clients" routerLinkActive="active" class="nav-link">Clients</a></li>
          <li class="nav-item"><a routerLink="events" routerLinkActive="active" class="nav-link">Events</a></li>
          <li class="nav-item"><a routerLink="about" routerLinkActive="active" class="nav-link">About</a></li>
          <li class="nav-item"><a routerLink="contact" routerLinkActive="active" class="nav-link">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>