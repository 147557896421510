import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobsService } from 'src/app/service/jobs.service';
import { ModalJobDetailsComponent } from '../modal-job-details/modal-job-details.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  latestJobs: any;
  clients = [
    { id: 1, alt: 'Client 1', src: 'assets/images/healthcare_client_log/care_health.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/healthcare_client_log/doha.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/healthcare_client_log/gmc.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/healthcare_client_log/idea.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/healthcare_client_log/kings.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/healthcare_client_log/poineers.png' },
    { id: 2, alt: 'Client 2', src: 'assets/images/healthcare_client_log/sulaiman.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/healthcare_client_log/tabeeb.jpeg' },
    { id: 1, alt: 'Client 1', src: 'assets/images/gcc/ajex.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/al-baik.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/americana.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/baumat.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/coacola.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/dawood.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/general_trading.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/gmc.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/inno.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/kaprel.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/kudu.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/life.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/modern_bus.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/nabati.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/nadec.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/paris.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/pizza.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/qaid.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/saif.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/savola.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/shaker.jpeg' },
    { id: 2, alt: 'Client 2', src: 'assets/images/gcc/sunbula.jpeg' },
  ];

  constructor(
    private _NgbModal: NgbModal,
    private service : JobsService,
  ) { }

  ngOnInit() {
    this.latestJobsGet()
    this.latestJobs = [
      { "title":"Driver", "salary":"42000", "posted_on":"20-10-2023","location":"Mumbai" },
      { "title":"Sales Man", "salary":"42000", "posted_on":"20-10-2023","location":"Mumbai" },
      { "title":"Cook", "salary":"42000", "posted_on":"20-10-2023","location":"Mumbai" },

    ]
  }
  openModal(datas){
    const modalRef = this._NgbModal.open(ModalJobDetailsComponent, {
      windowClass: 'modal-job-scrollable'
    });
    modalRef.componentInstance.data = datas
  }

  latestJobsGet(){
    this.service.getLatestJobsList().subscribe(data=>{
      this.latestJobs = data["result"]
      console.log("data",data)
    })
  }


}


