<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <h1 style="font-weight: 800;color: #242582;">Pharamacists</h1>
                            <p>
                                A pharmacist is an individual in the medical profession who is in charge of assessing prescriptions for patients, managing the pharmacy, delivering and labeling medications, and advising patients. They monitor patient histories, make sure that medications are used safely, and keep up with medical breakthroughs.
                             </p>
                             <p>
                            Our pharma recruitment team at Bicha Travels & HR Consultant is known for the special capacity to satisfy every possible need of our pharmaceutical clients. we keep recruiting for the reputed pharmaceutical groups in Qatar, Oman, KSA and UAE. Those pharmacists with MOH, DHA, HAAD, Qatar Prometric and Oman prometric can visit our office or website to apply for the overseas job opportunities.  We set up a consistent recruiting procedure and provide our clients with live applicant tracking. Therefore, there is no chance that any of our clients will ever feel misled.
                             </p>
                        <!-- <button class="btn btn-primary">SEND THE MAIL</button> -->
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 100px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/7659acb627bb807333426f8a11fa775d.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</section>
