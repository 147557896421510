<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <h1 style="font-weight: 800;color: #242582;">Nurses</h1>
                        <p>
                            Nurses are supposed to work as forerunners of health care all over the world as they are mostly required for foreign nations. Bicha Travels & HR Consultant serves as an intermediary between nurses and health care groups (including Hospitals, clinics and medical centers). we help nurses to find their dream employment in their chosen places and pay scales. Aside from that, we provide clients with custom-tailored staffing services based on their assignments to meet their business objectives.
                        </p>

                        <p>
                        Bicha Travels & HR Consultant is a workforce recruitment agency that provides unique nursing recruitment services. We provide the most current details on the availability of nursing opportunities to candidates and qualified candidates to the clients all over the world. Our organization has an international track record as a result of more than 36 years of service in this industry. We have thousands of candidates in our database. In a nutshell, Bicha Travels keeps working as a bridge between Indian nurses and health care groups.
                        </p>

                        <p>
                        Although we provide services worldwide, the majority of the countries we work with are in the Middle East, including Qatar, the United Arab Emirates, Kuwait, Saudi Arabia, Bahrain, and Oman, as well as Singapore and  Maldives. You are provided with a variety of nursing hiring services within your anticipated time and at a very affordable cost.

                        </p>
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 220px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/7659acb627bb807333426f8a11fa775d.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</section>
