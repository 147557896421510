<section class="ftco-section-parallax">
    <div class="parallax-img d-flex align-items-center">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-md-7 text-center heading-section heading-section-white ftco-animate">
            <h2>Get Touch With Us</h2>
            <p>Need assistance with your job search or recruitment needs? We're here to help! Feel free to reach out to us </p>
            <div class="row d-flex justify-content-center mt-4 mb-4">
              <div class="col-md-8">
               <div  class="subscribe-form"> 
                  <div class="form-group  text-center" >
                    <!-- <input type="submit" value=""  class="" > -->
                    <a class="submit btn button px-3 text-center" routerLink="regform" style="border-radius: 5px; background: #4a4b4a;" >Enquiry</a>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer class="ftco-footer ftco-bg-dark ftco-section">
    <div class="container">
      <div class="row mb-5">
          <div class="col-md">
           <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">About</h2>
            <p>Bicha Travels, leading tours & travel company in Malabar with its registered office at Calicut. We offer a broad range of services which includes Domestic and international holidays, Air ticketing, Corporate travels, Visa and passport services, Umrah services and Travel insurance.
            </p>
            <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-3">
              <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
              <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
              <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
            </ul>
          </div>
        </div>
        <div class="col-md">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">Employers</h2>
            <ul class="list-unstyled">
              <li><a href="#" class="py-2 d-block">How it works</a></li>
              <li><a href="#" class="py-2 d-block">Register</a></li>
              <li><a href="#" class="py-2 d-block">Post a Job</a></li>
              <li><a href="#" class="py-2 d-block">Advance Skill Search</a></li>
              <li><a href="#" class="py-2 d-block">Recruiting Service</a></li>
              <li><a href="#" class="py-2 d-block">Blog</a></li>
              <li><a href="#" class="py-2 d-block">Faq</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md">
          <div class="ftco-footer-widget mb-4 ml-md-4">
            <h2 class="ftco-heading-2">Workers</h2>
            <ul class="list-unstyled">
              <li><a href="#" class="py-2 d-block">How it works</a></li>
              <li><a href="#" class="py-2 d-block">Register</a></li>
              <li><a href="#" class="py-2 d-block">Post Your Skills</a></li>
              <li><a href="#" class="py-2 d-block">Job Search</a></li>
              <li><a href="#" class="py-2 d-block">Emploer Search</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md">
          <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2">Have a Questions?</h2>
              <div class="block-23 mb-3">
                <ul>
                  <li><span class="icon icon-map-marker"></span><span class="text">Door No:23/163,23/164 &nbsp; &nbsp;&nbsp; Bicha Complex, Near Sindhu Theatre, Wayanad Road, Kunnamangalam Calicut,Kerala</span></li>
                  <li><a href="#"><span class="icon icon-phone"></span><span class="text">+91 8078880074</span></a></li>
                  <li><a href="#"><span class="icon icon-envelope"></span><span class="text">bichatravels89@gmail.com</span></a></li>
                </ul>
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">

          <p></p>
        </div>
      </div>
    </div>
  </footer>