import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(private _http : HttpClient ) { }

  private api_URL = environment.api_URL;
  private headerDict = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
};
private requestOptions = {
  headers: new HttpHeaders(this.headerDict)
};

getJobsList(): Observable<any>{
  console.log("url",this.api_URL)
  return this._http.get(environment.api_URL+'api_jobs/',this.requestOptions)
  .pipe(map(response=>{
    return response
  }))

}
getLatestJobsList(): Observable<any>{
  console.log("url",this.api_URL)
  return this._http.get(environment.api_URL+'api_jobs/LatestJobsList',this.requestOptions)
  .pipe(map(response=>{
    return response
  }))

}

registration(obj){
  return this._http.post(environment.api_URL+'api_home/EnquiryForm/',JSON.stringify(obj),this.requestOptions)
  .pipe(map(response=>{
    return response
  }))
}

getLatestJobsRole(): Observable<any>{
  console.log("url",this.api_URL)
  return this._http.get(environment.api_URL+'api_home/ListJobCategories/',this.requestOptions)
  .pipe(map(response=>{
    return response
  }))

}
}
