import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { IndexComponent } from './components/index/index.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { ModalEnquiryComponent } from './components/modal-enquiry/modal-enquiry.component';
import { ContactComponent } from './components/contact/contact.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HealthcareComponent } from './components/healthcare/healthcare.component';
import { GccComponent } from './components/gcc/gcc.component';
import { EuropeComponent } from './components/europe/europe.component';
import { ClientsComponent } from './components/clients/clients.component';
import { EventsComponent } from './components/events/events.component';
import { NursesComponent } from './components/healthcare/nurses/nurses.component';
import { PharamacistsComponent } from './components/healthcare/pharamacists/pharamacists.component';
import { MedicalTechniciansComponent } from './components/healthcare/medical-technicians/medical-technicians.component';
import { DoctorsComponent } from './components/healthcare/doctors/doctors.component';


const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path:'jobs',
    component:JobsComponent
  },
  {
    path:"regform",
    component:ModalEnquiryComponent
  },
   {
    path:"contact",
    component:ContactComponent
  },
  {
    path:"healthcare",
    component:HealthcareComponent
  },
  {
    path:"gcc",
    component:GccComponent
  },
  {
    path:"europe",
    component:EuropeComponent
  },
  {
    path:"clients",
    component:ClientsComponent
  },
  {
    path:"events",
    component:EventsComponent
  },
  {
    path:"healthcare/nurses",
    component:NursesComponent
  },
  {
    path:"healthcare/pharamacists",
    component:PharamacistsComponent
  },
  {
    path:"healthcare/medical-technicians",
    component:MedicalTechniciansComponent
  },
  {
    path:"healthcare/doctors",
    component:DoctorsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers:[],
  exports: [RouterModule]
})
export class AppRoutingModule { }
