import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-technicians',
  templateUrl: './medical-technicians.component.html',
  styleUrls: ['./medical-technicians.component.css']
})
export class MedicalTechniciansComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
