
<div class="modal-header">
	<h4 class="modal-title" style="font-weight: 600;">Job Details</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="text-center">

 
  <h2 class="text-center">{{data.title}}</h2>
  <p class="ftco-heading-2">Salary : <span> {{data.salary}}</span></p>
    <p class="ftco-heading-2">Location : <span>{{data.location}}</span></p>
    <p class="ftco-heading-2">Age : <span>{{data.age}} 22 - 42</span></p>
    <p class="ftco-heading-2">Remark : <span>{{data.remark}} Plus two qualification, Must have knowledge english </span></p>
        <p class="ftco-heading-2"> Posted On : <span>{{data.posted_on}}</span></p>
            <!-- <p class="ftco-heading-2">Job Code : <span>{{data.slug}}</span></p> -->
            <p class="ftco-heading-2">Contact Via Number : +91 8078880074</p>
        </div>
</div>
