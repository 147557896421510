<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <h1 style="font-weight: 800;color: #242582;">Doctors</h1>
                        <p>
                            We are one of the top worldwide doctor recruitment companies because of our true methods and meticulous attention to overseas recruitment of doctors. Our collaboration with doctors, surgeons, healthcare providers, and other medical professional staffing companies enables us to source the top medical professionals in any given specialization who can quickly assemble your team and establish enduring bonds. To discuss your needs, feel free to reach out to our doctor recruitment team. 
                             </p>
                             <p>
                            Bicha Travels & HR Consultant works together with healthcare institutions, clinics, surgical groups, and other enterprises which hold various needs and standards for hiring physicians and doctors . Our hiring procedures have been improved over time to make sure that the right person is employed at the right time for the right job by bridging any gaps in your medical staffing needs. Our experts at the Bicha Travels & HR Consultant are dedicated to bringing the doctors and physicians  in touch with the greatest medical groups.

                             </p>
                        <!-- <button class="btn btn-primary">SEND THE MAIL</button> -->
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 100px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/7659acb627bb807333426f8a11fa775d.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</section>
