import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-healthcare',
  templateUrl: './healthcare.component.html',
  styleUrls: ['./healthcare.component.css']
})
export class HealthcareComponent implements OnInit {

  clients = [
    { id: 1, name: 'Client 1', logoUrl: 'assets/images/healthcare_client_log/care_health.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/healthcare_client_log/doha.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/healthcare_client_log/gmc.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/healthcare_client_log/idea.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/healthcare_client_log/kings.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/healthcare_client_log/poineers.png' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/healthcare_client_log/sulaiman.jpeg' },
    { id: 2, name: 'Client 2', logoUrl: 'assets/images/healthcare_client_log/tabeeb.jpeg' },
    // Add more client objects as needed
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
