import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/service/jobs.service';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-enquiry',
  templateUrl: './modal-enquiry.component.html',
  styleUrls: ['./modal-enquiry.component.css']
})
export class ModalEnquiryComponent implements OnInit {
  fName;
  lName;
  email;
  gender;
  dob;
  pNumber;
  sNumber;
  place;
  remarks:string;
  fNameReq: boolean = false;
  genderReq: boolean =false;
  pNumberReq: boolean = false;
  jobsReq :boolean = false;
  otherJobReq : boolean = false;
  job:number[];
  jobRoles: any;
  otherJob :any;

  constructor(
    // private _NgbActiveModal: NgbActiveModal
    private service : JobsService,
    private toast:ToastrService

  ) { }

  ngOnInit() {
    this.getJobRoles();
  }
  // get activeModal() {
  //   return this._NgbActiveModal;
  // }

  changeGender(value){
    console.log("value",value)
    this,this.gender = value
  }

  getJobRoles(){
    this.service.getLatestJobsRole().subscribe(data=>{
      this.jobRoles = data
      console.log("role",data)
    })
  }

  validate(){
    
    console.log("othrole",this.otherJob)
    if(this.fName == undefined){
      this.fNameReq = true;
    }else if(this.gender == undefined){
      this.genderReq =true
    }else if(this.pNumber == undefined){
      this.pNumberReq = true 
    }else if(this.job == undefined){
      this.jobsReq = true;    
    }
    // else if(this.otherJob == undefined){
    //   this.otherJobReq == true;
    // }
    else{
      this.candidteReg();
    }
  }

  candidteReg(){
    console.log("gender",this.gender)
    let obj = new Object()
     obj["name"] = this.fName + this.lName;
     obj["email"] = this.email != undefined? this.email : null;
     obj["dob"] = this.dob != undefined ? this.dob : null;
     obj["gender"] = this.gender;
     obj["primary_contact"] = this.pNumber;
     obj["place"] = this.place != undefined ? this.place : null;
     obj["secondary_contact"] = this.sNumber != undefined ? this.sNumber : null;
     obj["remark"] = this.remarks != undefined ? this.remarks : null;
     obj["desigination"] = this.job != undefined ? this.job : null;
     obj["other"] = this.otherJob != undefined ? this.otherJob : null;
     this.service.registration(obj).subscribe(data=>{
      if(data["code"]==200){
        this.toast.success("Submitted Successfully")
      }else{
        this.toast.warning(data["message"])
      }
       this.clear();
     })
  }

  clear(){
    this.fName = '';
    this.lName = '';
    this.gender = '';
    this.dob = '';
    this.email = '';
    this.job = [];
    this.place = '';
    this.pNumber = '';
    this.sNumber = '';
    this.otherJob = '';
    this.remarks = '';
    this.fNameReq = false;
    this.pNumberReq = false;
    this.genderReq = false;
    this.otherJobReq = false;
    this.jobsReq = false;
  }

}
