
<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-6" style="margin-top: 100px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/subair.jpeg" height="600" alt="">
                    </div>
                </div>
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <h3 style="font-weight: 800;color: #242582;">Chairman’s message</h3>
                        <p>
                            Our vision is to foster the utmost degree of values and encourage jobseekers to aspire for greater goals. Our mission is to lay emphasis on hard work, open communication, team work and a higher level of responsibility.  <br>
                            As a chairman of Bicha group, I anticipate the full-fledged devotion of all the jobseekers and employers to transform those visions into mission and dreams into reality. And if there is anything requires my assistance, may it be personal or professional, I shall be there to hear from you.  <br>
                             I have the firm belief that Bicha group will continue to flourish to zenith of excellence with cooperation of foreign employers and candidates who wish to migrate abroad. I have always strived to deliver the end-to-end recruitment service in true and fair manner. <br>
                            I take this opportunity to express my hearty gratitude to every effort that’s made to growth and development of this company and to request to extend the same in the future as well. 
                             </p>
                        <!-- <button class="btn btn-primary">SEND THE MAIL</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</section>


<section>
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-top: 40px;">
            <div class="row">
                <div class="col-md-6" style="padding: 40px;">
                    <div class="text-center" style="margin-top: 100px;">
                        <!-- <h1 style="font-weight: 800;color: #242582;">ABOUT US</h1> -->
                        <p>
                            We offer you a secure, comfortable deployment abroad and we are distinct from others by particular processing throughout your overseas job journey. <br>
                            Bicha Travels& Hr Consultant is a leading healthcare recruitment agency registered with the Ministry of external affairs. So, you feel safe and fearless to be with us to fulfill your dreams. We guarantee that the end-to-end handling of your application is carried out straightforwardly and with steady back from our HR Administrators.
                             </p>
                        <!-- <button class="btn btn-primary">SEND THE MAIL</button> -->
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 100px;">
                    <div class="abt-img">
                        <img src="../../../assets/images/7659acb627bb807333426f8a11fa775d.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</section>


